import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Paper,
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TampilAngsuranChild = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idAngsuranChild } = useParams();
  const navigate = useNavigate();

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Data Surat Penarikan
  const [noStSuratPenarikan, setNoStSuratPenarikan] = useState("");
  const [tglStSuratPenarikan, setTglStSuratPenarikan] = useState("");
  const [suratPenarikanKolektor, setSuratPenarikanKolektor] = useState("");
  const [angPerBulanSuratPenarikan, setAngPerBulanSuratPenarikan] =
    useState("");
  const [jmlBlnTelatSuratPenarikan, setJmlBlnTelatSuratPenarikan] =
    useState("");
  const [totalDendaSuratPenarikan, setTotalDendaSuratPenarikan] = useState("");
  const [biayaTarikSuratPenarikan, setBiayaTarikSuratPenarikan] = useState("");

  // Data Angsuran
  const [mainId, setMainId] = useState("");
  const [idAngsuran, setIdAngsuran] = useState("");
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");

  // Data Inputan
  const [tglBayar, setTglBayar] = useState("");
  const [tglBayarText, setTglBayarText] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kolektor, setKolektor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [namaCOA, setNamaCOA] = useState("");
  const [kasBank, setKasBank] = useState("");
  const [denda, setDenda] = useState("");
  const [potongan, setPotongan] = useState("");
  const [jemputan, setJemputan] = useState("");
  const [biayaTarik, setBiayaTarik] = useState("");
  const [hutangDenda, setHutangDenda] = useState("");
  const [totalPiutang, setTotalPiutang] = useState("");
  const [totalBayar, setTotalBayar] = useState("");
  const [bayar, setBayar] = useState("");
  const [bayarDenda, setBayarDenda] = useState("");
  const [isPercepatan, setIsPercepatan] = useState(false);

  const [inputMd1, setInputMd1] = useState("");
  const [inputMd2, setInputMd2] = useState("");
  const [inputMd3, setInputMd3] = useState("");
  const [inputMdTerakhir, setInputMdTerakhir] = useState("");

  const [tanggalJual, setTanggalJual] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tenor, setTenor] = useState("");
  const [noJual, setNoJual] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [namaSurveyor, setNamaSurveyor] = useState("");
  const [jualId, setJualId] = useState("");
  const [penerimaanId, setPenerimaanId] = useState("");

  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);

  const [isDisabledMd1, setIsDisabledMd1] = useState(true);
  const [isDisabledMd2, setIsDisabledMd2] = useState(true);
  const [isDisabledMd3, setIsDisabledMd3] = useState(true);
  const [isLast, setIsLast] = useState(false);

  const [isPost, setIsPost] = useState(false);
  const [bayarPercepatan, setBayarPercepatan] = useState(false);
  const [bayarKe, setBayarKe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const classes = useStyles();

  let findMonth = (monthNumber) => {
    if (monthNumber === 1) {
      return "JANUARI";
    } else if (monthNumber === 2) {
      return "FEBRUARI";
    } else if (monthNumber === 3) {
      return "MARET";
    } else if (monthNumber === 4) {
      return "APRIL";
    } else if (monthNumber === 5) {
      return "MEI";
    } else if (monthNumber === 6) {
      return "JUNI";
    } else if (monthNumber === 7) {
      return "JULI";
    } else if (monthNumber === 8) {
      return "AGUSTUS";
    } else if (monthNumber === 9) {
      return "SEPTEMBER";
    } else if (monthNumber === 10) {
      return "OKTOBER";
    } else if (monthNumber === 11) {
      return "NOVEMBER";
    } else if (monthNumber === 12) {
      return "DESEMBER";
    }
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getCOAsData();
    getAngsuranChildById();
    getJualById();
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const getAngsuranChildById = async () => {
    if (id) {
      const findAngsuranId = await axios.post(`${tempUrl}/angsuransByNoJual`, {
        noJual: id,
        ke: idAngsuranChild,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setMainId(findAngsuranId.data._id);
      const response = await axios.post(`${tempUrl}/angsuransFindChild`, {
        noJual: id,
        ke: idAngsuranChild,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      if (response.data.suratPenarikan) {
        setTglStSuratPenarikan(formatDate(response.data.suratPenarikan.tglSt));
        setNoStSuratPenarikan(response.data.suratPenarikan.noSt);
        if (response.data.suratPenarikan.kodeKolektor) {
          setSuratPenarikanKolektor(
            `${response.data.suratPenarikan.kodeKolektor.kodeKolektor} - ${response.data.suratPenarikan.kodeKolektor.namaKolektor}`
          );
        }
        setAngPerBulanSuratPenarikan(response.data.suratPenarikan.angPerBulan);
        setJmlBlnTelatSuratPenarikan(response.data.suratPenarikan.jmlBlnTelat);
        setTotalDendaSuratPenarikan(response.data.suratPenarikan.totalDenda);
        setBiayaTarikSuratPenarikan(response.data.suratPenarikan.biayaTarik);
      }

      if (findAngsuranId.data.bayarKe - 1 == response.data._id) {
        // Find if Last
        setIsLast(true);
      }

      setIdAngsuran(response.data._id);
      setTglJatuhTempo(response.data.tglJatuhTempo);
      setTglJatuhTempoFormatted(formatDate(response.data.tglJatuhTempo));
      setAngModal(response.data.angModal);
      setAngBunga(response.data.angBunga);
      setAngPerBulan(response.data.angPerBulan);

      let tglBayarDate = new Date(response.data.tglBayar);
      setTglBayarText(
        `${tglBayarDate.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })} ${findMonth(
          tglBayarDate.getMonth() + 1
        )} ${tglBayarDate.getFullYear()}`
      );

      setTglBayar(formatDate(response.data.tglBayar));
      setNoKwitansi(response.data.noKwitansi);
      setKeterangan(response.data.keterangan);
      setDenda(response.data.denda);
      setPotongan(response.data.potongan);
      setJemputan(response.data.jemputan);
      setBiayaTarik(response.data.biayaTarik);
      setHutangDenda(response.data.hutangDenda);
      setTotalPiutang(response.data.totalPiutang);
      setTotalBayar(response.data.totalBayar);
      setBayar(response.data.bayar);
      setIsPercepatan(response.data.isPercepatan);
      if (response.data.kodeSurveyor) {
        setKodeKolektor(
          `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
        );
        setKolektor(response.data.kodeSurveyor.namaSurveyor);
      }

      let isMd1Active =
        response.data.tglBayar === null && response.data.md1 === null;
      let isMd2Active = response.data.md1 && response.data.md2 === null;
      let isMd3Active =
        response.data.md1 && response.data.md2 && response.data.md3 === null;

      if (isMd1Active) {
        setIsDisabledMd1(false);
      } else if (isMd2Active) {
        setIsDisabledMd1(false);
        setIsDisabledMd2(false);
        response.data.md1 && setInputMd1(new Date(response.data.md1));
      } else if (isMd3Active) {
        response.data.md1 && setInputMd1(new Date(response.data.md1));
        response.data.md2 && setInputMd2(new Date(response.data.md2));
        setIsDisabledMd1(false);
        setIsDisabledMd2(false);
        setIsDisabledMd3(false);
      } else {
        response.data.md1 && setInputMd1(new Date(response.data.md1));
        response.data.md2 && setInputMd2(new Date(response.data.md2));
        response.data.md3 && setInputMd3(new Date(response.data.md3));
      }

      if (response.data.isPercepatan === true) {
        // Find Penerimaan Percepatan
        const penerimaanAngsuran = await axios.post(
          `${tempUrl}/penerimaansByNoJualShowPercepatan`,
          {
            noJual: id,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setPenerimaanId(penerimaanAngsuran.data._id);
        setIsPost(penerimaanAngsuran.data.isPost);
        setBayarPercepatan(penerimaanAngsuran.data.bayar);
        setBayarKe(penerimaanAngsuran.data.bayarKe);
        setKasBank(penerimaanAngsuran.data.COA.kasBank);
        setKodeCOA(penerimaanAngsuran.data.COA.kodeCOA);
        setNamaCOA(penerimaanAngsuran.data.COA.namaCOA);
        setBayarDenda(penerimaanAngsuran.data.bayarDenda);
      } else {
        // Find Penerimaan
        const penerimaanAngsuran = await axios.post(
          `${tempUrl}/penerimaansByNoJualBayarKe`,
          {
            noJual: id,
            bayarKe: idAngsuranChild,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        if (penerimaanAngsuran.data !== null) {
          setPenerimaanId(penerimaanAngsuran.data._id);
          setIsPost(penerimaanAngsuran.data.isPost);
          setBayarPercepatan(penerimaanAngsuran.data.bayar);
          setBayarKe(penerimaanAngsuran.data.bayarKe);
          setKasBank(penerimaanAngsuran.data.COA.kasBank);
          setKodeCOA(penerimaanAngsuran.data.COA.kodeCOA);
          setNamaCOA(penerimaanAngsuran.data.COA.namaCOA);
          setBayarDenda(penerimaanAngsuran.data.bayarDenda);
        }
      }
    }
  };

  const getJualById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setJualId(response.data._id);
      setTanggalJual(formatDate(response.data.tanggalJual));
      setNamaRegister(response.data.namaRegister); // potong 1 kata cukup
      setAlmRegister(response.data.almRegister);
      setNoRangka(response.data.noRangka);
      setNopol(response.data.nopol);
      setTipe(response.data.tipe);
      setNamaWarna(response.data.namaWarna);
      setTenor(response.data.tenor);
      setNoJual(response.data.noJual);
      setKodeSurveyor(response.data.kodeSurveyor.kodeSurveyor);
      setNamaSurveyor(response.data.kodeSurveyor.namaSurveyor);
    }
  };

  const downloadPdfCetakKwitansiAngsuran = async () => {
    const tempTipe = await axios.post(`${tempUrl}/tipesByKode`, {
      kodeTipe: tipe,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    let newJatuhTempo = new Date(tglJatuhTempo);
    let tempJatuhTempo = formatDate(newJatuhTempo);
    let findMonth = (monthNumber) => {
      if (monthNumber === 1) {
        return "JANUARI";
      } else if (monthNumber === 2) {
        return "FEBRUARI";
      } else if (monthNumber === 3) {
        return "MARET";
      } else if (monthNumber === 4) {
        return "APRIL";
      } else if (monthNumber === 5) {
        return "MEI";
      } else if (monthNumber === 6) {
        return "JUNI";
      } else if (monthNumber === 7) {
        return "JULI";
      } else if (monthNumber === 8) {
        return "AGUSTUS";
      } else if (monthNumber === 9) {
        return "SEPTEMBER";
      } else if (monthNumber === 10) {
        return "OKTOBER";
      } else if (monthNumber === 11) {
        return "NOVEMBER";
      } else if (monthNumber === 12) {
        return "DESEMBER";
      }
    };
    let monthJatuhTempo = findMonth(newJatuhTempo.getMonth() + 1);
    var date = new Date();
    var current_date =
      date.getDate() +
      " " +
      findMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear();
    let tempX1 = 54;
    let tempY = 42;
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(`${noKwitansi}`, 170, 19);
    doc.text(`${kodeSurveyor} - ${namaSurveyor.split(" ", 1)[0]}`, 165, 22);
    doc.text(`${namaRegister} / ${noJual}`, tempX1, tempY);
    tempY += 6;
    doc.text(`${almRegister.slice(0, 45)}`, tempX1, tempY);
    tempY += 9;
    doc.setFont(undefined, "bold");
    doc.text(`${angkaTerbilang(bayar)} rupiah`, tempX1, tempY);
    doc.setFont(undefined, "normal");
    tempY += 10;
    doc.text(
      `ANGSURAN SEWA BELI 1 (satu) unit sepeda motor ${tempTipe.data.merk}`,
      tempX1,
      tempY
    );
    tempY += 8;
    doc.text(
      `Warna : ${namaWarna}. ${nopol} / ${noRangka} - ${tipe} `,
      tempX1,
      tempY
    );
    tempY += 6;
    doc.text(
      `Angsuran Ke-${idAngsuran} (${angkaTerbilang(
        idAngsuran
      )}) Dari ${tenor} (${angkaTerbilang(
        tenor
      )}). Kontrak TGL. ${tanggalJual}`,
      tempX1,
      tempY
    );
    tempY += 7;
    doc.text(
      `Untuk Angsuran Bulan ${monthJatuhTempo} ${newJatuhTempo.getFullYear()}`,
      tempX1,
      tempY
    );
    if (kasBank === "BANK") {
      doc.text(`TRANSFER`, 120, tempY + 10);
    }
    tempY += 18;
    if (kolektor) {
      doc.text(`Jemput            :`, 130, tempY);
      doc.text(`${kolektor}`, 155, tempY);
    }
    tempY += 4;
    if (hutangDenda > 0) {
      doc.text(`Hutang Denda :`, 130, tempY);
      doc.text(`${hutangDenda.toLocaleString("en-US")}`, 180, tempY, {
        align: "right",
      });
    }
    if (suratPenarikanKolektor) {
      doc.text(`EKS: ${suratPenarikanKolektor}`, 50, tempY);
    }
    tempY += 4;
    if (bayarDenda > 0) {
      doc.text(`Bayar Denda   :`, 130, tempY);
      doc.text(`${bayarDenda.toLocaleString("en-US")}`, 180, tempY, {
        align: "right",
      });
    }
    if (biayaTarik > 0) {
      doc.text(`Biaya Tarik: ${biayaTarik.toLocaleString("en-US")}`, 50, tempY);
    }
    doc.setFont(undefined, "bold");
    doc.text(`${bayar.toLocaleString("en-US")}`, tempX1 + 10, tempY);
    doc.setFont(undefined, "normal");
    doc.text(`${tglBayarText}`, 140, tempY);
    tempY += 37;
    doc.text(`${namaRegister.split(" ").slice(0, 2).join(" ")}`, tempX1, tempY);
    doc.text(`( ${user.username} )`, 140, tempY);
    tempY += 10;
    doc.text(
      `Pembayaran transfer hanya ke : ${user.cabang.noRekeningCabang}`,
      tempX1,
      tempY
    );
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfCetakKwitansiPercepatanAngsuran = async () => {
    const tempStok = await axios.post(`${tempUrl}/daftarStoksByNorang`, {
      noRangka,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    let newJatuhTempo = new Date(tglJatuhTempo);
    let tempJatuhTempo = formatDate(newJatuhTempo);
    let findMonth = (monthNumber) => {
      if (monthNumber === 1) {
        return "JANUARI";
      } else if (monthNumber === 2) {
        return "FEBRUARI";
      } else if (monthNumber === 3) {
        return "MARET";
      } else if (monthNumber === 4) {
        return "APRIL";
      } else if (monthNumber === 5) {
        return "MEI";
      } else if (monthNumber === 6) {
        return "JUNI";
      } else if (monthNumber === 7) {
        return "JULI";
      } else if (monthNumber === 8) {
        return "AGUSTUS";
      } else if (monthNumber === 9) {
        return "SEPTEMBER";
      } else if (monthNumber === 10) {
        return "OKTOBER";
      } else if (monthNumber === 11) {
        return "NOVEMBER";
      } else if (monthNumber === 12) {
        return "DESEMBER";
      }
    };
    let monthJatuhTempo = findMonth(newJatuhTempo.getMonth() + 1);
    var date = new Date();
    var current_date =
      date.getDate() +
      " " +
      findMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear();
    let tempX1 = 50;
    let tempY = 38;
    const doc = new jsPDF();
    doc.setFontSize(9);
    doc.text(`${noKwitansi}`, 184, 15);
    doc.text(`${idAngsuran} - ${namaSurveyor.split(" ", 1)[0]}`, 184, 18);
    doc.text(`${namaRegister}`, tempX1, tempY);
    tempY += 4;
    doc.text(`${almRegister.slice(0, 45)}`, tempX1, tempY);
    tempY += 13;
    doc.setFont(undefined, "bold");
    doc.text(`${angkaTerbilang(bayarPercepatan)} rupiah`, tempX1, tempY);
    doc.setFont(undefined, "normal");
    tempY += 9;
    doc.text(
      `PELUNASAN 1 (satu) unit sepeda motor ${tempStok.data.merk}`,
      tempX1,
      tempY
    );
    tempY += 4;
    doc.text(
      `Warna : ${namaWarna}. ${nopol} / ${noRangka} - ${tipe} `,
      tempX1,
      tempY
    );
    tempY += 4;
    doc.text(
      `Angsuran Ke-${bayarKe} (${angkaTerbilang(
        bayarKe
      )}) s/d ${tenor} (${angkaTerbilang(tenor)}).`,
      tempX1,
      tempY
    );
    tempY += 4;
    doc.text(`Kontrak ${noJual}. TGL. ${tempJatuhTempo}`, tempX1, tempY);
    tempY += 40;
    doc.setFont(undefined, "bold");
    doc.text(`${bayarPercepatan.toLocaleString("en-US")}`, tempX1 + 10, tempY);
    doc.setFont(undefined, "normal");
    doc.text(`${current_date}`, 140, tempY);
    tempY += 44;
    doc.text(`${namaRegister.split(" ").slice(0, 2).join(" ")}`, tempX1, tempY);
    doc.text(`( ${user.username} )`, 140, tempY);
    tempY += 10;
    doc.text(
      `Pembayaran transfer hanya ke : ${user.cabang.noRekeningCabang}`,
      tempX1,
      tempY
    );
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const saveAngsuran = async (e) => {
    e.preventDefault();
    let md1;
    let md2;
    let md3;
    let mdTerakhir;
    inputMd1 &&
      (md1 =
        inputMd1?.getFullYear() +
        "-" +
        (inputMd1?.getMonth() + 1) +
        "-" +
        inputMd1?.getDate());

    inputMd2 &&
      (md2 =
        inputMd2?.getFullYear() +
        "-" +
        (inputMd2?.getMonth() + 1) +
        "-" +
        inputMd2?.getDate());

    inputMd3 &&
      (md3 =
        inputMd3?.getFullYear() +
        "-" +
        (inputMd3?.getMonth() + 1) +
        "-" +
        inputMd3?.getDate());

    inputMdTerakhir &&
      (mdTerakhir =
        inputMdTerakhir?.getFullYear() +
        "-" +
        (inputMdTerakhir?.getMonth() + 1) +
        "-" +
        inputMdTerakhir?.getDate());

    try {
      setLoading(true);

      // If Able edit COA
      if (user.tipeUser !== "ADM" && penerimaanId) {
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Penerimaan
        await axios.post(`${tempUrl}/updatePenerimaan/${penerimaanId}`, {
          COA: tempCOA.data._id,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
      }

      // If able edit Md
      if (
        isDisabledMd1 === false ||
        isDisabledMd2 === false ||
        isDisabledMd3 === false
      ) {
        // Update Jual
        const response = await axios.post(`${tempUrl}/jualByNoJual`, {
          noJual: id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/updateJual/${response.data._id}`, {
          tglMd1: md1 ? md1 : "",
          tglMd2: md2 ? md2 : "",
          tglMd3: md3 ? md3 : "",
          tglMdTerakhir: mdTerakhir,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Angsuran
        await axios.post(`${tempUrl}/updateAngsuranNoPenerimaan/${mainId}`, {
          angsuranKe: idAngsuranChild - 1,
          _id: parseInt(idAngsuranChild),
          tglJatuhTempo,
          angModal,
          angBunga,
          angPerBulan,
          tglBayar,
          noKwitansi,
          keterangan,
          denda,
          potongan,
          jemputan,
          biayaTarik,
          hutangDenda,
          totalPiutang,
          totalBayar,
          bayar,
          md1,
          md2,
          md3,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
      }
      setLoading(false);
      navigate(`/daftarAngsuran/angsuran/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAngsuran = async () => {
    setLoading(true);
    try {
      if (isPercepatan) {
        let findBayarAngsuran = await axios.post(
          `${tempUrl}/findBayarAngsuran/${mainId}`,
          {
            angsuranKe: idAngsuran - 1,
            _id: idAngsuran,
            id: user._id,
            token: user.token,
          }
        );

        const angsuransFindChildBefore = await axios.post(
          `${tempUrl}/angsuransFindChild`,
          {
            noJual: id,
            ke: tenor - findBayarAngsuran.data - 2,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );

        let tempHutangDendaBefore = 0;
        if (angsuransFindChildBefore.data) {
          tempHutangDendaBefore = angsuransFindChildBefore.data.hutangDenda;
        }

        let findLastTitip = await axios.post(`${tempUrl}/lastTitipByNoJual`, {
          noJual: id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        let tempUpdateJualData = {
          idJual: jualId,
          hutangDenda: tempHutangDendaBefore,
          sisaBulan: tenor - findBayarAngsuran.data,
          tglJatuhTempo,
          ketBlacklist: "",
          id: user._id,
          token: user.token,
        };

        if (findLastTitip.data) {
          tempUpdateJualData["isTitip"] = true;
          tempUpdateJualData["tglTitip"] = findLastTitip.data.tglPenarikan;
        }

        if (tenor - idAngsuranChild === 0) {
          tempUpdateJualData["tglLunas"] = null;
        }

        // Cancel Bayar Percepatan Angsuran Transaction
        await axios.post(
          `${tempUrl}/cancelBayarPercepatanAngsuranTransaction`,
          {
            angsuranData: {
              idAngsuran: mainId,
              angsuranKe: idAngsuran - 1,
              _id: idAngsuran,
              tglBayar: "",
              noKwitansi: "",
              tglJatuhTempo,
              angModal,
              angBunga,
              angPerBulan,
              denda: 0,
              potongan: 0,
              jemputan: 0,
              biayaTarik,
              hutangDenda: 0,
              totalPiutang,
              totalBayar,
              bayar: 0,
              md1: inputMd1,
              md2: inputMd2,
              md3: inputMd3,
              bayarKe: findBayarAngsuran.data + 1,
              id: user._id,
              token: user.token,
            },
            angsuranBayarKeData: {
              idAngsuran: mainId,
              bayarKe: findBayarAngsuran.data + 1,
              id: user._id,
              token: user.token,
            },
            jualData: tempUpdateJualData,
            id: user._id,
            token: user.token,
          }
        );

        // // Delete Percepatan Loop
        // await axios.post(`${tempUrl}/cancelBayarPercepatanAngsuran/${mainId}`, {
        //   angsuranKe: idAngsuran - 1,
        //   _id: idAngsuran,
        //   tglBayar: "",
        //   noKwitansi: "",
        //   tglJatuhTempo,
        //   angModal,
        //   angBunga,
        //   angPerBulan,
        //   denda: 0,
        //   potongan: 0,
        //   jemputan: 0,
        //   biayaTarik,
        //   hutangDenda: 0,
        //   totalPiutang,
        //   totalBayar,
        //   bayar: 0,
        //   md1: inputMd1,
        //   md2: inputMd2,
        //   md3: inputMd3,
        //   bayarKe: findBayarAngsuran.data + 1,
        //   id: user._id,
        //   token: user.token,
        // });

        // await axios.post(`${tempUrl}/updateAngsuranBayarKe/${mainId}`, {
        //   bayarKe: findBayarAngsuran.data + 1,
        //   id: user._id,
        //   token: user.token,
        // });

        // await axios.post(`${tempUrl}/updateJual/${jualId}`, tempUpdateJualData);
      } else {
        const angsuransFindChildBefore = await axios.post(
          `${tempUrl}/angsuransFindChild`,
          {
            noJual: id,
            ke: idAngsuranChild - 1,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );

        let tempHutangDendaBefore = 0;
        if (angsuransFindChildBefore.data) {
          tempHutangDendaBefore = angsuransFindChildBefore.data.hutangDenda;
        }

        let findLastTitip = await axios.post(`${tempUrl}/lastTitipByNoJual`, {
          noJual: id,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        let tempUpdateJualData = {
          idJual: jualId,
          hutangDenda: tempHutangDendaBefore,
          sisaBulan: tenor - idAngsuran + 1,
          tglJatuhTempo,
          id: user._id,
          token: user.token,
        };

        if (findLastTitip.data) {
          tempUpdateJualData["isTitip"] = true;
          tempUpdateJualData["tglTitip"] = findLastTitip.data.tglPenarikan;
        }

        if (tenor - idAngsuranChild === 0) {
          tempUpdateJualData["tglLunas"] = null;
        }

        // Cancel Bayar Angsuran Transaction
        await axios.post(`${tempUrl}/cancelBayarAngsuranTransaction`, {
          angsuranData: {
            idAngsuran: mainId,
            angsuranKe: idAngsuran - 1,
            _id: idAngsuran,
            tglBayar: "",
            noKwitansi: "",
            tglJatuhTempo,
            angModal,
            angBunga,
            angPerBulan,
            denda: 0,
            potongan: 0,
            jemputan: 0,
            biayaTarik,
            hutangDenda: 0,
            totalPiutang,
            totalBayar,
            bayar: 0,
            md1: inputMd1,
            md2: inputMd2,
            md3: inputMd3,
            id: user._id,
            token: user.token,
          },
          angsuranBayarKeData: {
            idAngsuran: mainId,
            bayarKe: idAngsuran,
            id: user._id,
            token: user.token,
          },
          jualData: tempUpdateJualData,
          id: user._id,
          token: user.token,
        });

        // // Update Cancel Bayar Angsuran
        // await axios.post(`${tempUrl}/cancelBayarAngsuran/${mainId}`, {
        //   angsuranKe: idAngsuran - 1,
        //   _id: idAngsuran,
        //   tglBayar: "",
        //   noKwitansi: "",
        //   tglJatuhTempo,
        //   angModal,
        //   angBunga,
        //   angPerBulan,
        //   denda: 0,
        //   potongan: 0,
        //   jemputan: 0,
        //   biayaTarik,
        //   hutangDenda: 0,
        //   totalPiutang,
        //   totalBayar,
        //   bayar: 0,
        //   md1: inputMd1,
        //   md2: inputMd2,
        //   md3: inputMd3,
        //   id: user._id,
        //   token: user.token,
        // });
        // await axios.post(`${tempUrl}/updateAngsuranBayarKe/${mainId}`, {
        //   bayarKe: idAngsuran,
        //   id: user._id,
        //   token: user.token,
        // });

        // await axios.post(`${tempUrl}/updateJual/${jualId}`, tempUpdateJualData);
      }
      navigate(`/daftarAngsuran/angsuran/${id}`);
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${idAngsuranChild} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarAngsuran/angsuran/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Piutang</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Angsuran Ke-
        </Typography>
        <Box sx={downloadButtons}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              startIcon={<PrintIcon />}
              onClick={() => {
                if (isPercepatan === false) {
                  // Cetak Angsuran Biasa
                  downloadPdfCetakKwitansiAngsuran();
                } else {
                  // Cetak Percepatan Angsuran
                  downloadPdfCetakKwitansiPercepatanAngsuran();
                }
              }}
            >
              CETAK KWITANSI
            </Button>
          </ButtonGroup>
        </Box>

        {isPost === false && isLast && user.tipeUser === "MGR" && (
          <Box sx={downloadButtons}>
            <ButtonGroup variant="contained">
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${idAngsuran}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteAngsuran(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Divider sx={dividerStyle} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={idAngsuran} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jatuh Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJatuhTempoFormatted} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglBayar} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Kwitansi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKwitansi} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={keterangan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Kolektor :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeKolektor} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA :
                  </Form.Label>
                  <Col sm="8">
                    {user.tipeUser !== "ADM" && kodeCOA ? (
                      <Form.Control
                        value={`${kodeCOA} - ${namaCOA}`}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenCOA()}
                      />
                    ) : (
                      <Form.Control
                        value={kodeCOA ? `${kodeCOA} - ${namaCOA}` : ""}
                        disabled
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran Modal :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angModal.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran Bunga :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angBunga.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran / Bulan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angPerBulan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={denda.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Potongan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={potongan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jemputan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jemputan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Biaya Tarik :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={biayaTarik.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Hutang Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={hutangDenda.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Piutang :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPiutang.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalBayar.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={bayar.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bayar Denda :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={bayarDenda.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />

        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Surat Penarikan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noStSuratPenarikan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Surat Penarikan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglStSuratPenarikan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Eksekutor :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={suratPenarikanKolektor} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        biayaTarikSuratPenarikan + totalDendaSuratPenarikan
                      ).toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jml Bulan Telat :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${jmlBlnTelatSuratPenarikan} bulan`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Angsuran / Bulan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={angPerBulanSuratPenarikan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda Tunggakan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalDendaSuratPenarikan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Biaya Tarik :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={biayaTarikSuratPenarikan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>

        <Divider sx={dividerStyle} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Md1 :
                  </Form.Label>
                  <Col sm={8}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      readOnly={isDisabledMd1}
                      selected={inputMd1}
                      onChange={(e) => {
                        setInputMd1(e);
                        setInputMdTerakhir(e);
                      }}
                      customInput={
                        <TextField
                          InputProps={{
                            readOnly: isDisabledMd1,
                          }}
                          sx={{
                            backgroundColor: isDisabledMd1 && Colors.grey400,
                            width: "100%",
                          }}
                          size="small"
                        />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Md2 :
                  </Form.Label>
                  <Col sm={8}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      readOnly={isDisabledMd2}
                      selected={inputMd2}
                      onChange={(e) => {
                        setInputMd2(e);
                        setInputMdTerakhir(e);
                      }}
                      customInput={
                        <TextField
                          InputProps={{
                            readOnly: isDisabledMd2,
                          }}
                          sx={{
                            backgroundColor: isDisabledMd2 && Colors.grey400,
                            width: "100%",
                          }}
                          size="small"
                        />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Md2 :
                  </Form.Label>
                  <Col sm={8}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      readOnly={isDisabledMd3}
                      selected={inputMd3}
                      onChange={(e) => {
                        setInputMd3(e);
                        setInputMdTerakhir(e);
                      }}
                      customInput={
                        <TextField
                          InputProps={{
                            readOnly: isDisabledMd3,
                          }}
                          sx={{
                            backgroundColor: isDisabledMd3 && Colors.grey400,
                            width: "100%",
                          }}
                          size="small"
                        />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}></Box>
        </Box>
        <Box sx={spacingTop}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={saveAngsuran}
          >
            Simpan
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          setNamaCOA(user.namaCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TampilAngsuranChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
  mb: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    md: 4,
  },
  marginTop: {
    md: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};
